<template>
  <v-container>
    <br>
    <v-row style="justify-content: center;">
      <v-alert dense border="top" color="info" dark>
        {{ selectedLanguage['pricing_component_text1'] }} ( ₡1 ) {{ selectedLanguage['pricing_component_text2'] }} ${{ creditPrice }}
      </v-alert>
    </v-row>
    <v-row style="justify-content: center;">
      <v-alert dense border="top" color="warning" dark>
        {{ selectedLanguage['pricing_component_text3'] }}
      </v-alert>
    </v-row>
    <br>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ selectedLanguage['pricing_component_text4'] }}
            </th>
            <th class="text-left">
              {{ selectedLanguage['pricing_component_text5'] }}
            </th>
            <th class="text-left">
              {{ selectedLanguage['pricing_component_text6'] }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in products" :key="item.name">
            <td>{{ item.description }}</td>
            <td>{{ Math.abs(item.value) }}</td>
            <td>{{ item.product_type }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { portuguese } from '../languages/pt-br';
  import { english } from '../languages/en';

  export default {
    name: 'Pricing',
    components: {},
    computed: {
      ...mapGetters(['products','creditPrice','profile']),
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
    },
    mounted: async function(){
      
    },
    data: () => ({
    }),
    methods: {
      // ...mapActions(['getMonetize']) TODO: get just products and creditPrice
    },
  }
</script>