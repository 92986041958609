<template>
  <v-container>
    <Navigation/>
    <Pricing/>
    <br> 
    <!-- <v-row style="justify-content: center;"> TODO: Incluir consulta para remover cadastro de cartão
      <v-btn :color="color" dark class="mb-2" @click="updateCC=true">Remove credit card</v-btn>
    </v-row> -->
    <CardForm v-if="!paymentId || updateCC && buyFlowActivated" :update="updateCC" @update="updateCC=false" @cancel="cancelUpdateFlow()"/>
    <v-dialog v-model="dialog" max-width="600px" style="z-index: 99999;" v-if="buyFlowActivated">
      <div style="background-color: white; padding: 30px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <h4>Para aquisição de creditos entre em contato com o suporte</h4>
      </div>
      <v-btn color="primary" @click="cancelDialog()"> Sair </v-btn>
    </v-dialog>
    <!-- <v-row v-if="buyFlowActivated && !updateCC" style="justify-content: center;">
      <v-data-table :headers="creditsHeaders" :hide-default-footer="true" :items="creditsTable" class="elevation-1">
        <template v-slot:top> 
          <v-toolbar flat>
            <v-toolbar-title>{{ selectedLanguage['billingMercadoPago_component_text1'] }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer/>
            <v-btn :color="color" dark class="mb-2" @click="updateCC=true">{{ selectedLanguage['billingMercadoPago_component_text2'] }}</v-btn>
            <v-btn text @click="buyFlowActivated=false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn :loading="item.loading" :color="color" dark small class="mr-2" @click="(confirmBuyDialog=true,buyItem=item)">
            {{ selectedLanguage['billingMercadoPago_component_text3'] }}
          </v-btn>
        </template>
      </v-data-table>
    </v-row> -->
    <br><v-divider/><br><br>
    <v-data-table
    :headers="transactionsHeaders"
    :items="transactionsFormatted"
    class="elevation-1"
    :search="search"
    style="margin-bottom: 60px;"
    :footer-props="{
    showFirstLastPage: true,
    'items-per-page-text': selectedLanguage['billing_text_data_2']
    }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ selectedLanguage['billingMercadoPago_component_text4'] }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="selectedLanguage['billing_text_data_1']"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-toolbar flat>
          <!-- <v-toolbar-title>CREDITS TRANSACTIONS</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field> -->
          <!-- <v-spacer/>
          <v-btn :color="color" disabled class="mb-2" @click="transferCredits()">TRANSFER</v-btn> -->
          <v-spacer/>
          <v-btn :disabled="buyFlowActivated" :color="color" dark style="width: 45%; margin: 10px" @click="buyFlowActivated=true" x-large>{{ selectedLanguage['billingMercadoPago_component_text5'] }}</v-btn>
          <v-spacer/>
          <v-btn :color="color" dark style="width: 45%; margin: 10px" @click="exportExcel()" x-large><v-icon>mdi mdi-table-arrow-down</v-icon> {{ selectedLanguage['billingMercadoPago_component_text6'] }}</v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="item.invoice!='none'" small class="mr-2" @click="openInvoice(item.invoice)">
          mdi-receipt-text
        </v-icon>
        <!-- <v-icon v-else small class="mr-2" @click="openDetail(item.detail)">
          mdi-help
        </v-icon> -->
      </template>
    </v-data-table>
    <v-dialog v-if="buyItem" v-model="confirmBuyDialog" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedLanguage['billingMercadoPago_component_text7'] }}
        </v-card-title>
        <v-card-text>{{ selectedLanguage['billingMercadoPago_component_text8'] }} ₡{{buyItem['quantity']}} {{ selectedLanguage['billingMercadoPago_component_text9'] }} ${{ buyItem['price'] }}.</v-card-text>
        <v-alert v-if="billingMessage" dense border="top" color="error" dark>
          {{ billingMessage }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" variant="text" @click="confirmBuyDialog=false">{{ selectedLanguage['billingMercadoPago_component_text10'] }}</v-btn>
          <v-btn :color="color" :loading="buyLoading" dark variant="text" @click="buy()">{{ selectedLanguage['billingMercadoPago_component_text11'] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { URLS } from '../constants'
  import CardForm from './CardForm'
  import { mapGetters, mapActions } from 'vuex'
  import Navigation from './Navigation'
  import Pricing from './Pricing'
  import { portuguese } from '../languages/pt-br'
  import { english } from '../languages/en'

  import * as XLSX from 'xlsx/xlsx.mjs';

  // var XLSX = require("xlsx");

  /* load 'fs' for readFile and writeFile support */
  import * as fs from 'fs';
  XLSX.set_fs(fs);

  /* load 'stream' for stream support */
  import { Readable } from 'stream';
  XLSX.stream.set_readable(Readable);

  /* load the codepage support library for extended support with older formats  */
  import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
  XLSX.set_cptable(cpexcel);

  export default {
    name: 'Billing',
    components: {Navigation, CardForm, Pricing},
    computed: {
      ...mapGetters(['transactions','credit','products','paymentId','transactionsFormatted','creditPrice','profile']),
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
    },
    mounted: async function(){
      await this.getTransactions()
      await this.setCreditsTable()
      await this.initializeBilling()
      this.getMonetize()
    },
    data: () => ({
      color: '#0A7373',
      dialog: true,
      search: '',
      updateCC: false,
      buyFlowActivated: false,
      confirmBuyDialog: false,
      buyItem: undefined,
      buyLoading: false,
      paymentBrickValue: 10,
      methods: [],
      billingMessage: undefined,
      transactionsHeaders: [
        { text: 'Date', value: 'date'},
        { text: 'Description', value: 'product.description'},
        { text: 'Status', value: 'status'},
        { text: 'Type - Tittle', value: 'calc_type'},
        { text: 'Value', value: 'value'},
        { text: 'Balance', value: 'balance_new'},
        { text: 'Actions', value: 'actions'},
      ],
      creditsHeaders: [
        { text: 'Credits', value: 'qtty'},
        { text: 'Price ($)', value: 'price'},
        { text: 'Action', value: 'actions'},
      ],
      creditsTable: [{'quantity': 10},{'quantity': 100},{'quantity': 1000},{'quantity': 5000},{'quantity': 10000},{'quantity': 50000},{'quantity': 100000},{'quantity': 500000},{'quantity': 1000000}],
      // products: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        value: 0,
      },
      defaultItem: {
        name: '',
        value: 0,
      },
    }),
    methods: {

      formatValue(value) {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
      },

      initializeBilling(){
        this.transactionsHeaders = this.selectedLanguage['billing_data_obj_1']
        this.creditsHeaders = this.selectedLanguage['billing_data_obj_2']

        this.formatTransactions()
      },
      callPaymentBrick(){
        window.location.href = 'https://api.wwcalc.com/mercado-pago/payment_bricks?email='+this.$store.getters.profile.email+'&language='+this.$store.getters.profile.language
      },
      cancelDialog(){
        this.buyFlowActivated = false
      },
      exportExcel(){
        let data = document.getElementsByTagName('table')[1]
        var fp = XLSX.utils.table_to_book(data,{raw:true})
        XLSX.write( fp, {
          bookType:'xlsx',
          type:'base64'
        })
        XLSX.writeFile(fp, 'transactions.xlsx')
      },
      ...mapActions(['formatTransactions', 'buyCredits','removePaymentId','getTransactions','getMonetize']),
      async transferCredits() {
        this.methods = ['PIX','Bank transfer']
        if(this.payment_methods.length>0){
          this.methods.push('Credit card xxx')
        }
        // console.log('transfer')
        //FORCED credit card
      },
      async cancelUpdateFlow () {
        // console.log('cancelUpdateFlow')
        this.updateCC=false
        this.buyFlowActivated=false
      },
      async buy () {
        this.billingMessage = undefined
        this.buyLoading=true
        const res = await this.buyCredits(this.buyItem['quantity'])
        if(res==true){
          this.confirmBuyDialog=false
        }
        else{
          this.billingMessage = res
        }
        this.buyLoading=false
      },
      openInvoice (filename) {
        const url = URLS.apidownload+'/download/'+this.profile.hash_id+'/_/invoice/'+filename
        // console.log('openInvoice',url)
        // this.pdfReport(url)
        // this.$router.push('/pdfReport')
        // window.open(url,'_blank')
        window.open(url)
      },
      openDetail (item) {
        this.editedIndex = this.transactions.indexOf(item)
        this.editedItem = Object.assign({}, item)
        // console.log('openDetail',this.editedIndex,this.editedItem)
      },
      setCreditsTable () {
        this.creditsTable.forEach(item => {
          item['price'] = (item['quantity']*this.creditPrice).toLocaleString()
          item['qtty'] = item['quantity'].toLocaleString()
          item['loading'] = false
        })
      }
    },
  }
</script>