<template>
  <v-container>
    <div class='card-wrapper'></div>
    <v-form id="cc-form" v-model="form" @submit.prevent="onSubmit">
      <v-text-field v-model="number" :readonly="loading" :rules="[required]" clearable :label="selectedLanguage['cardForm_component_text1']" outlined :placeholder="selectedLanguage['cardForm_component_text2']" style="border-radius: 10px !important;" id="cc-number"/>
      <v-text-field v-model="full_name" :readonly="loading" :rules="[required]" clearable :label="selectedLanguage['cardForm_component_text3']" outlined :placeholder="selectedLanguage['cardForm_component_text4']" style="border-radius: 10px !important;" id="cc-name"/>
      <v-text-field v-model="expiry_date" :readonly="loading" :rules="[required]" clearable :label="selectedLanguage['cardForm_component_text5']" outlined :placeholder="selectedLanguage['cardForm_component_text5']" style="border-radius: 10px !important;" id="cc-expiration"/>
      <v-text-field v-model="cvv" :readonly="loading" :rules="[required]" clearable :label="selectedLanguage['cardForm_component_text6']" outlined :placeholder="selectedLanguage['cardForm_component_text7']" style="border-radius: 10px !important;" id="cc-cvv"/>
      <v-btn v-if="!form" @click="$emit('cancel')" block>
        {{ selectedLanguage['cardForm_component_text8'] }}
      </v-btn>
      <v-btn v-else :loading="loading" type="submit" block>
        {{ selectedLanguage['cardForm_component_text9'] }}
      </v-btn>
      <v-alert v-if="updateCardMessage" dense border="top" color="error" dark>
        {{ updateCardMessage }}
      </v-alert>
      <v-alert v-if="createCardMessage" dense border="top" color="error" dark>
        {{ createCardMessage }}
      </v-alert>
    </v-form>
  </v-container>
</template>

<script>
  import * as Card from "card"
  import { mapActions, mapGetters } from 'vuex'
  import { portuguese } from "../languages/pt-br";
  import { english } from "../languages/en";

  export default{
    name: "FormCreditCard",
    computed: {
      ...mapGetters(['customerId', 'profile']),
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
    },
    props: {
      update: Boolean
    },
    data: () => ({
      form: false,
      number: null,
      full_name: null,
      expiry_date: null,
      cvv: null,
      loading: false,
      updateCardMessage: undefined,
      createCardMessage: undefined
      }),
    mounted() {
      new Card({ 
        form: "form#cc-form",
        container: ".card-wrapper",
        formSelectors: { 
          numberInput: "input#cc-number",
          expiryInput: "input#cc-expiration",
          cvcInput: "input#cc-cvv",
          nameInput: "input#cc-name"
        },
        width: 270,
        formatting: true,
        placeholders: {
          number: "•••• •••• •••• ••••",
          name: "Nome Completo",
          expiry: "••/••",
          cvc: "•••"
        }
      });
    },
    methods:{
      ...mapActions(['createCreditCard','updateCreditCard']),
      async onSubmit () {
        this.updateCardMessage = undefined
        this.createCardMessage = undefined
        if (!this.form) return
        this.loading = true
        var month = Number(this.expiry_date.slice(0,2))
        var year = Number("20"+this.expiry_date.slice(-2))
        this.number = ((this.number.replace(/[^.\d]/g, '')).replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2"))
        var payload = {
          "customer_id": this.customerId,
          "name": "stripe",
          "description": "Stripe Payment",
          "number": Number(this.number),
          "exp_month": month,
          "exp_year": year,
          "cvc": Number(this.cvv),
          "customer_name": this.full_name,
        }
        if(this.update){
          const res = await this.updateCreditCard(payload)
          if(res==true){
            this.$emit('update')
          }
          else{
            this.updateCardMessage = res
            // this.updateCardMessage = 'Error during card update. Please, verify card data and try again.'
          }          
        }else{
          const res = await this.createCreditCard(payload)
          if(res!=true){
            this.createCardMessage = res
            // this.createCardMessage = 'Error during card registration. Please, verify card data and try again.'
          }
        }
        this.loading = false
      },
      required (v) {
        return !!v || 'Field is required'
      }
    },
  }
</script>
